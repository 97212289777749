import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) {}

  getHeaders(): HttpHeaders {
    const token = localStorage.getItem('accessToken') || '';
    let header = new HttpHeaders({
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    });
    if (token) {
      header = header.set('Authorization', 'Bearer ' + token);
    }
    return header;
  }

  post<T = unknown>(url: string, data: unknown = null): Observable<T> {
    return this.http.post<T>(url, data, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.checkRequest(error);
        return throwError(() => error);
      })
    );
  }

  postWithProgress<T = unknown>(
    url: string,
    data: unknown = null
  ): Observable<HttpEvent<T>> {
    return this.http
      .post<T>(url, data, {
        headers: this.getHeaders(),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(() => error);
        })
      );
  }

  get<T = unknown>(url: string, params?: HttpParams): Observable<T> {
    return this.http
      .get<T>(url, { headers: this.getHeaders(), params: params })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(() => error);
        })
      );
  }

  update<T = unknown>(url: string, data: unknown): Observable<T> {
    return this.http.patch<T>(url, data, { headers: this.getHeaders() }).pipe(
      catchError((error) => {
        this.checkRequest(error);
        return throwError(() => error);
      })
    );
  }

  updateWithProgress<T = unknown>(
    url: string,
    data: unknown
  ): Observable<HttpEvent<T>> {
    return this.http
      .patch<T>(url, data, {
        headers: this.getHeaders(),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(() => error);
        })
      );
  }

  delete<T = unknown>(url: string, data: unknown): Observable<T> {
    return this.http
      .delete<T>(url, { headers: this.getHeaders(), body: data })
      .pipe(
        catchError((error) => {
          this.checkRequest(error);
          return throwError(() => error);
        })
      );
  }

  checkRequest(error: HttpErrorResponse): void {
    if (
      error.status === 401 ||
      error.status === 403 ||
      error?.error?.message === 'Invalid Token'
    ) {
      localStorage.removeItem('accessToken');
      this.router.navigate(['/login']);
    }
    try {
      this.showError(error).then();
    } catch (e) {
      console.error(e);
    }
  }

  async showError(error: HttpErrorResponse): Promise<void> {
    if (error.status === 500) {
      return;
    }
    if (error.error) {
      if (error.error instanceof Blob) {
        return;
      }

      if (Array.isArray(error.error)) {
        error.error.forEach((eachError) => {});
        return;
      }
      if (Object.keys(error.error)) {
        Object.keys(error.error).forEach((key) => {});
        return;
      }
    }
    if (typeof error === 'string') {
      return;
    }
  }

  downloadFile(
    url: string,
    params?: HttpParams,
    options?: {
      includeToken?: boolean;
    }
  ): Observable<Blob> {
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    });

    if (options?.includeToken) {
      const token = localStorage.getItem('accessToken') || '';
      if (token) {
        headers = headers.set('Authorization', 'Bearer ' + token);
      }
    }

    return this.http.get(url, {
      responseType: 'blob',
      headers,
      params,
    });
  }

  handleProgressResponse<T>(
    event: HttpEvent<T>,
    {
      uploadCallback,
      successCallback,
    }: {
      uploadCallback: (eventProgress: HttpProgressEvent) => void;
      successCallback: () => void;
    }
  ) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        uploadCallback(event);
        break;
      case HttpEventType.Response:
        successCallback();
        break;
    }
  }

  getRequestProgress(event: HttpProgressEvent): number {
    return event.total ? Math.round((100 * event.loaded) / event.total) : 0;
  }
}
