import { environment } from '../../environments/environment';

export const BACKEND_URL = environment.baseUrl + '/api/';

export const BACKEND_API = {
  auth: BACKEND_URL + 'auth/token/',
  adminUser: BACKEND_URL + 'user/admin-user/',
  userRole: BACKEND_URL + 'user/user-permissions/role/',
  userPermissions: BACKEND_URL + 'user/user-permissions/',
  program: BACKEND_URL + 'program/',
  programAllNames: BACKEND_URL + 'program/all-program-names/',
  programBulkDelete: BACKEND_URL + 'program/bulk-delete/',
  programUpdateSequence: BACKEND_URL + 'program/update-sequence/',
  programWeekly: BACKEND_URL + 'program-weekly/',
  programWeeklyBulkDelete: BACKEND_URL + 'program-weekly/bulk-delete/',
  programWeeklyUpdateSequence: BACKEND_URL + 'program-weekly/update-sequence/',
  programDaily: BACKEND_URL + 'program-daily/',
  programDailyBulkDelete: BACKEND_URL + 'program-daily/bulk-delete/',
  programDailyUpdateSequence: BACKEND_URL + 'program-daily/update-sequence/',
  event: BACKEND_URL + 'event/',
  eventAllNames: BACKEND_URL + 'event/all-event-names/',
  eventBulkDelete: BACKEND_URL + 'event/bulk-delete/',
  eventUpdateSequence: BACKEND_URL + 'event/update-sequence/',
  eventAvaliable: BACKEND_URL + 'event/available-events/',
  eventUserAttendance: BACKEND_URL + 'event/user-attendance/',
  badge: BACKEND_URL + 'badge/',
  badgeBulkDelete: BACKEND_URL + 'badge/bulk-delete/',
  programQuestion: BACKEND_URL + 'journal/journal-question/',
  programQuestionBulkDelete:
    BACKEND_URL + 'journal/journal-question/bulk-delete/',
  programQuestionUpdateSequence:
    BACKEND_URL + 'journal/journal-question/update-sequence/',
  innerx: BACKEND_URL + 'user/mobile-user/',
  userAvailableBadge: BACKEND_URL + 'badge/user-available-badges/',
  connectUserBadge: BACKEND_URL + 'badge/user-badge/',
  unlinkUserBadge: BACKEND_URL + 'badge/user-badge/bulk-delete/',
  generalSettingAdmin:
    BACKEND_URL + 'app-configs/mobile-general-settings-admin/',
  generalSetting: BACKEND_URL + 'app-configs/mobile-general-settings/',
  forgetPassword: BACKEND_URL + 'auth/forget-password/',
  resendForgetPasswordOtp: BACKEND_URL + 'auth/forget-password/resend-otp/',
  resetPassword: BACKEND_URL + 'auth/reset-password/',
  exportGeneralLeadership:
    BACKEND_URL + 'user/mobile-user/export/general-leadership',
  exportWeeklyLeadership:
    BACKEND_URL + 'user/mobile-user/export/weekly-leadership',
  mobileAppVersionLatest: BACKEND_URL + 'app-configs/mobile-app-version/latest',
  mobileAppManifest: BACKEND_URL + 'app-configs/mobile-app-manifest',
};
